
@import '../sass/variables.scss';

.container{
   width: 100%;
   height: calc(100vh - 100px);
   
   //flex: 1 0;
   display: grid;
   grid: 80% 20% / 35% 65%;
   columns: 2;
   // background-color: #F2F4F6;
   position: relative;
   overflow-x: hidden;
   overflow-y: hidden;
}

.leftWorkArea{
   grid-column: 1/ span 1;
   grid-row: 1/span 1;
   min-height: 100%;
   margin-left: 2%;
   margin-right: 2%;
   overflow-x: hidden;
   overflow-y: hidden;
   
}

.rightPanel{
   grid-column: 2/ span 1;
   grid-row: 1/span 1;
   min-height: 100%;
   overflow-x: hidden;
   overflow-y: hidden;
}

@media screen and (max-width:600px) {

   .container{
      height: $container_ht;
      grid: $fabric_cell_ht $controls_cell_ht / 100%;
      columns: 1;
      margin-top: 5px;
   }
   .leftPanel{
      display: none;
   }
   .leftWorkArea{
      
      margin-top: 1vh;
      grid-column: 1/span 1;
      grid-row: 2/ span 1;
      background-color: $work-panel-color;
      max-height: $controls_cell_ht;
   }
   .rightPanel{
      grid-column: 1/span 1;
      grid-row: 1/span 1;
   }
   
}


// =================
.rulerUnits{
   height: 40px;
   width: 40px;
   display: flex;
   align-items: center;
   justify-content: center;
   color: #404040;
   font-size: 14px;
   font-weight: 600;
   font-family: var(--main-font);
   position: absolute;
   left: calc(42% - 40px);
   top: 1%;
   z-index: 99;
}

.rulerHorizontal{
   border-bottom: none;
   width: 55%;
   height: 40px;
   position: absolute;
   left: 42%;
   top: 1%;
   z-index: 99;
   overflow: hidden;
}

.rulerVertical{
   border-right: none;
   width: 40px;
   max-height: 80%;
   min-height: 0px;
   position: absolute;
   left:  calc(42% - 40px);
   top: calc(1% + 40px);
   z-index: 99;
   overflow: hidden;
}

.rulerVerticalScenGrid{
   border-right: 1px solid #404040;
   width: 40px;
   max-width: 40px;
   top: 0;
   left:0;
   z-index: 99;
   overflow: hidden;
}
// =================

.fabricCanvasDiv {
   left: $ruler_thickness;
   position:absolute;
   width: calc(100% - #{$ruler_thickness});
}

.fabric{
   border: 1px solid #404040;
   overflow: hidden;
   overflow-y: hidden;
   width: 55%;
   max-height: 80%;
   min-height: 40%;
   background-color: #fff;
   position: absolute;
   left: 42%;
   top: calc(1% + 40px);
   z-index: 11;
}

.fabricScenGrid{
   scrollbar-width: thin;
   scrollbar-color: #797979 #fff !important;
   border:none;
   overflow-x: hidden;
   overflow-y: auto;
   // width: calc(55% + 40px);
   width: calc(#{$ruler_thickness} + 55%);
   max-height: calc(80% - 40px);
   min-height: 40%;
   position: absolute;
   left: calc(42% - 40px);
   top: calc(1% + 40px);
   z-index: 11;
   // width: calc(#{$ruler_thickness} + #{$horizontal_margin} + #{$fabric_width});
}

@media screen and (max-width: 600px){
   
   .fabric{
      left: calc(#{$ruler_thickness} + #{$horizontal_margin});
      top: $ruler_thickness;
      min-height: $fabric_height;
      max-height: $fabric_height;
      width: $fabric_width;
   }
   .fabricScenGrid{
      // width: $fabric_width;
      width: calc(#{$ruler_thickness} + #{$horizontal_margin} + #{$fabric_width});
      max-height: $fabric_height;
      min-height: $fabric_height;
      left: $horizontal_margin;
      top: $ruler_thickness;
   }
   .rulerVertical{
      left: $horizontal_margin; 
      top:$ruler_thickness;
      min-height: $fabric_height;
      max-height: $fabric_height;
   }
   .rulerHorizontal{
       left: calc(#{$ruler_thickness} + #{$horizontal_margin});
       top:0;
       width: $fabric_width;
     
   }
   .rulerUnits{
      left: 0;
      width: 40px;
      font-size: 10px;
   }
}

.scrollbar{
   //width: $scrollbar_thickness;
   scrollbar-width: thin;
   scrollbar-color: #797979 #fff !important;
   position: absolute;
   left: calc(97% - 1px);
   top: calc(1% + 40px);
   background-color: #fff;
   border: 1px solid #555;
   display: flex;
   align-items: center;
   z-index: 99;
   overflow: hidden;
   
   max-height: 80%;
}

@media screen and (max-width:600px){
   .scrollbar{
      left: $scollbar_left;
      top: $ruler_thickness;
      min-height: $fabric_height;
      max-height: $fabric_height;
   }
}

.addToCart{
   width: 17%;
   // max-width: 300px;
   // min-width: 200px;
   height: 7.1%;
   min-height: 40px;
   max-height: 50px;

   position: absolute;
   left: 80%;
   top: 90%;
   z-index: 11;
}
@media screen and (max-width: 600px){
   .addToCart{
      display: none;
   }
}