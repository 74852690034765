.container{
   position: absolute;
   max-width: 280px;
   width: 80%;
   top:calc(100% - 15px);
   right: 0;
   background-color: #fff;
   box-shadow: 0 0 2px rgba(0,0,0,.2);
   z-index: 101;
}
.weight{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 10px 12px;
}
.color{
   width: 100%;
   display: flex;
   align-items: center;
   padding: 10px 12px;
   margin-bottom: 8px;
   &_cont{
      min-width: 22px;
      min-height: 22px;
      background:#fff;
      box-shadow: 0 0 3px 1px rgba(0,0,0,.2);
      &_dis{
         position: relative;
         &:before{
            content:'';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 1px;
            background-color: rgba(0,0,0,.2);
            transform: translate(-50%, -50%) rotate(45deg);
         }
      }
   }
}
.title{
   font-family: var(--main-font);
   font-weight: 500;
   font-size: 16px;
   color: #404040;
   margin-right: 10px;
}

.body{
   padding: 0 8px 8px 8px;
   display: flex;
   flex-wrap: wrap;
}
.btn_cont{
   padding: 4px;
   min-width: 30px;
   display: flex;
   align-items: center;
   justify-content: center;
}
.btn{
   border: none;
   outline: none;
   min-width: 22px;
   min-height: 22px;
   box-shadow: 0 0 3px rgba(0,0,0,.1);
   transition: all .3s ease;
   font-family: var(--main-font);
   font-size: 15px;
   cursor: pointer;
   &:hover{
      box-shadow: 0 0 4px 2px #4a68ff44;
   }
}