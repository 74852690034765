
@import '../../sass/variables.scss';

.container{
   width: 100%;
   position: relative;
   z-index: 999;
}
.select{
   width: 100%;
   height: 56px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   // padding: 0 24px;

   font-size: 1em;
   font-family: var(--mon);
   color: #404040;

   cursor: pointer;
   & *{
      pointer-events: none;
      user-select: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      transition: all .3s ease;
   }
   
}
.active_arr{
   transform: rotateX(180deg);
}
.option_cont{
   width: calc(100% + 20px);
   transition: all .3s ease;
   position: absolute;
   // position: fixed;

   left: -10px;
   
   max-height: 240px;
   overflow: hidden;
   overflow-y: auto;
   box-shadow: 0 0 4px #aaa;
}
.option_btn{
   // width: calc(100% + 20px);
   width: 100%;
   height: 48px;
   background-color: #fff;
   color: #404040;

   font-size: 1em;
   font-family: var(--mon);
   font-weight: 500;
   text-align: left;
   padding: 0 10px;
   opacity: .9;
   transition: all .3s ease;
   border: none;
   outline: none;
   &:hover{
      opacity: 1;
      background-color: #E5E9FD;
   }
}

@media screen and  (max-width: 600px) {
   .select {
      font-size: 0.75rem;
      height: calc(0.3 * #{$header_height});
      background-color: white;
   }

   .option_btn{
      font-size: 0.75em;
      opacity: 0.95;
      height: calc(0.3 * #{$header_height});
   }
}