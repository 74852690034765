@import '../sass/variables.scss';
.container{
   width: 100%;
   height: 100%;
   //max-height: 650px;

   overflow-x: hidden;
   overflow-y: hidden;
   background-color: #fff;
   //padding-bottom: 10px;
   
}


.part{
   //min-height: 400px;
   background-color: #fff;
   height: 100%;
 
 &_length{
   border-bottom: 1px solid #DCDEE5;
   padding: 5px 4%;
   // height: 20%;
   overflow: hidden;
   display: flex;
   flex-direction: column;
   align-items: center;
 }
 &_addImg{
   height: 80%;
   // border-bottom: 1px solid #DCDEE5;
   padding: 5px 5px;

 }
 &_lengthparent{
   display: flex;
   flex-direction: row;
 
   width: 100%;
 }
}

.leftPanelAddToCart{
   display: none;
}

@media screen and (max-width: 600px){

   .container{
      padding-bottom: 0px;
      overflow-y: hidden;
   }
   
   .part{
      max-height: $controls_cell_ht;
      min-height: $controls_cell_ht;
      // background-color: grey;
      display: grid;
      grid: auto auto/ 100%;
      columns: 1;
      overflow-y: hidden;
      &_addImg{
         grid-column: 1/span 1;
         grid-row: 1/span 1;
         background-color: white;
         height: $fabric_tile_panel_ht;
         overflow: hidden;
         padding: 0px 0px;
        
      }
      &_lengthparent{
         display: flex;
         flex-direction: column-reverse;
      }
      &_length{
        height: $fabric_length_panel_ht;
        padding: 0px 0px;
        background-color: white;
        left: 0px;
        bottom: 5px;
        position: fixed;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        overflow: auto;
        align-items: center;
      }
      .leftPanelFabricPrice{
         display: flex;
      }
      .leftPanelClearAll{
         display: none;
      }
      .leftPanelAddToCart{
         display: flex;
      }
   }
}

.leftPanelFabricPrice{
   display: none;
}

.rmBtn{
   border: none;
   background-color: transparent;
   position: absolute;
   right: 10px;
   top: 50%;
   transform: translate(0 , -50%);
   cursor: pointer;
   & *{
      pointer-events: none;
   }
}

.strTitle{
   color: #404040;
   font-family: var(--main-font);
   font-weight: 600;
   font-size: 16px;
   white-space: nowrap;
   text-overflow: ellipsis;
   margin-right: 5px;
   padding-top: 5px;
}

.splitBtn{
   width: 70px;
   height: 32px;
   border: 1px solid #DCDEE6;
   outline: none;
   padding: 0 0 0 0px;

   color: #404040;

   font-family: var(--main-font);
   font-weight: 500;
}
.segmentTypeSelect{
   width: 130px;
   height: 32px;
   border: 1px solid #DCDEE6;
   outline: none;
   padding: 0 0 0 0px;

   color: #404040;

   font-family: var(--main-font);
   font-weight: 500;
}

// input[type=number]::-webkit-inner-spin-button {
//    height: 32px;
// }

// input[type=number]::-moz-number-spin-box {
//    height: 32px;
// }

.lengthInp{
   width: 70px;
   height: 32px;
   border: 1px solid #DCDEE6;
   outline: none;
   padding: 0 0 0 8px;

   color: #404040;

   font-family: var(--main-font);
   font-weight: 500;
   overflow: none;

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
 opacity: 1;
}

// =====================

.addImage{
   width: 100%;
   height: 100%;
}
.toggleBtn{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   background: transparent;

   color: #404040;

   font-family: var(--main-font);
   font-weight: 600;
   font-size: 16px;
   border: none;
   outline: none;
   padding: 5px 0;

   cursor: default;//pointer;
   & *{
      pointer-events: none;
   }
}

.toggleContent{
   border-top: 1px solid #DCDEE6;
   padding: 25px 0;
}
.toggleUpload{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   border-bottom: 1px solid #DCDEE6;
   height: 15%;
}
.imageConfig{
   height: 85%;
   
}

.upload{
   display: flex;
   align-items: center;

   text-transform: uppercase;

   color: #404040;

   font-family: var(--main-font);
   font-weight: 800;
   font-size: 12px;
   letter-spacing: 0.12em;
   position: relative;
   padding: 10px;

   border: none;
   outline: none;
   background: transparent;
   cursor: pointer;
   
   
   & img{
      margin-right: 5px; 
   }
   &_inpt{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
   }
}

button.upload{
   & *{
      pointer-events: none;
   }
}

.view3d{
   display: flex;
   align-items: center;
   position: relative;
   // padding: 10px;
   border: none;
   outline: none;
   background: transparent;
   cursor: pointer;
   
   & img{
      margin-right: 5px;
      width: 35px;
      height: 35px;
   }
   &_inpt{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
   }
}

.disabled {
   background-color:'transparent';
   opacity:0.2;
   pointer-events: none;
}

button.view3d{
   & *{
      display: none;
      pointer-events: none;
   }
}

.remove{
   border: none;
   background-color: transparent;
   right: 10px;
   width: 20px;
   height: 20px;
   border: none;
   outline: none;
   background: transparent;
   cursor: pointer;
   & *{
      pointer-events: none;
   }
}

button.remove{
   & *{
      pointer-events: none;
   }
}

.aspectRatio{
   border: none;
   background-color: transparent;
   right: 5px;
   width: 15px;
   height: 15px;
   border: none;
   outline: none;
   background: transparent;
   cursor: pointer;
   & *{
      pointer-events: none;
   }
}

button.aspectRatio{
   & *{
      pointer-events: none;
   }
}

.imgCont{
   width: 100%;
   &_gallery{
      width: 100%;
      display: flex;
      padding: 10px 0 ;
      min-height: 80px;
      & img{
         width: 80px;
         height: auto;
         object-fit: contain;
      }
   }
   &_size{
      display: flex;
      align-items: center;
      justify-content: space-between;
   }
}

.imgCm{
   width: 100%;
   &_size{
      margin-top: 20px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
   }
}

// .inputNumber.-webkit-outer-spin-button.-webkit-inner-spin-button {
   
//    -webkit-appearance: none;
//    margin: 0;
// }

.inputNumber {
   -moz-appearance: textfield;
   width: 100%;
   height: 32px;

   border: 1px solid grey;
   outline: none;

   font-family: var(--main-font);
   font-weight: bold;
   font-size: 10px;

   letter-spacing: 0.12em;
   text-transform: uppercase;
   margin: 0 5px;

   cursor: pointer;
}
      

.sizeText{
   width: 100%;
   height: 32px;

   border: 1px solid grey;
   outline: none;

   font-family: var(--main-font);
   font-weight: bold;
   font-size: 10px;

   letter-spacing: 0.12em;
   text-transform: uppercase;
   margin: 0 5px;

   cursor: pointer;
}

.sizeBtn{
   width: 100%;
   height: 32px;

   border: none;
   outline: none;

   font-family: var(--main-font);
   font-weight: bold;
   font-size: 10px;

   letter-spacing: 0.12em;
   text-transform: uppercase;

   color: #4A69FF;
   background: #E5E9FD;
   margin: 0 5px;

   cursor: pointer;
}

.repeat{
   width: 100%;
   padding: 15px 5px ;
   &_title{
      margin-bottom: 10px;
   }
   &_btns{
      width: 100%;
      height: 60px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
   }
}

.repeatBtn{
   height: 50px;
   &:not(:last-child){
      margin-right: 10px;
   }
}

.mobilePicker{
   display: none;
}

.lengthChangeBtn {
   display: none;
}

@media screen and (max-width: 600px) {

   .toggleUpload{
     height: calc(0.2*#{$fabric_tile_panel_ht});
   }
   .imageConfig{
      height: $fabric_image_config_ht
   }
   
   .toggleBtn{
      display: none;
   }

   .repeatBtn{
     //width: 15%;
     //height: 50px;
     height: calc(0.8 * 0.3*#{$fabric_image_config_ht});
     width: auto;
     max-height: calc(0.8 * 0.3*#{$fabric_image_config_ht});
     overflow: hidden;
     margin-right: 0px;

     &:not(:last-child){
        margin-right: 0px;
     }
   }

   .repeat{
      width: 100%;
      padding: 0px 0px ;
      &_title{
         margin-bottom: 0px;
         display: none;
      }
      &_btns{
        height: calc(0.3*#{$fabric_image_config_ht});
        max-height: calc(0.3*#{$fabric_image_config_ht});
        display: flex;
        flex-direction: row;
        align-items: center;
        
      }
   }

   .upload {
      font-size: 10px;
   }

   .strTitle{
      // display: none;
      font-weight: 600;
      font-size: 9px;
      margin-left: 10px;
      margin-top: 4px;
   }

   .mobilePicker{
      display: flex;
      width: 100%;
      position: absolute;
      bottom:0;
      left:0
   }
   .imgCont{
      height: calc(0.6*#{$fabric_image_config_ht});
      max-height: calc(0.6*#{$fabric_image_config_ht});
      overflow: hidden;
      margin-top: -px8;
   }
  
   // /* Chrome, Safari, Edge, Opera */
   // input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
   //    -webkit-appearance: none;
   //    margin: 0;
   // }

   // /* Firefox */
   // input[type=number] {
   //    -moz-appearance: textfield;
   //    width: 65%;
   //    align-items: center;
   //    text-align: center;
   //  }

   .lengthChangeBtn {
      display: flex;
      background-color: black;
      color: white;
      width: 32px;
      height: 32px;
      outline: none;
      padding-left: 8px;
      align-items: center;
      text-align: center;
      justify-content: center;
      border: none;
    }
    .libContainer{
       display: none;
    }
}



