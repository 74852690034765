.playground-container{
    width: 100%; 
}
.editor-container {
    width: 100%; 
    display: flex; 
    flex-wrap: wrap;
    padding-left: 1%;
    padding-top: 1%;
    padding-right: 2.5%;
    height: 90vh;
    overflow: hidden;
}

.horizontal-ruler-container {
    width: calc(99% - 30px); 
    height: 30px;
    margin-left: 30px;
}

.scrollable-container {
    display: flex;
    height: calc(90vh - 40px);
    width: 99%;
    overflow-y: scroll;
    border-bottom: 1px solid gray;
    scrollbar-width: thin;
}

.vertical-ruler-container {
    width: 30px;
    position: relative;
    border-top: 1px solid;
}

.scen-container{
    width: calc(99% - 30px);
}

.ruler-markings{
    color: black;
    border-color: black !important;
}
.upload-button-container{
   display: flex;
   justify-content: flex-end;
   width: 99%;
   padding-right: 2.5%;
}