@import '../sass/variables.scss';

.content{
   margin-left: $horizontal_margin;
   margin-right: $horizontal_margin;
   // margin-bottom: $vertical_margin;
   background-color:  #40404022;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column-reverse;
   width:80px;
   max-height:calc(0.2 * #{$controls_cell_ht});
   height: calc(0.2 * #{$controls_cell_ht});
 }

 .content_title{
    text-transform: capitalize;
    font-size: 10px;
    
 }