.Viewer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #E5E5E5;
    //position: fixed;
}

.viewerParent{
    width: 100%;
    height: 100%;
    // position: fixed;
}

.hideSelf{
    display: none;
}
