// .parentContainer {
//     display: flex;
//     width: 100%;
//     height: 100%;
//     z-index: 99;
// }

// .modal-content {
//     background-color: #fefefe;
//     margin: auto;
//     padding: 20px;
//     border: 1px solid #888;
//     width: 80%;
//   }
  
//   .close {
//     color: #aaaaaa;
//     float: right;
//     font-size: 28px;
//     font-weight: bold;
//   }
  
//   .close:hover,
//   .close:focus {
//     color: #000;
//     text-decoration: none;
//     cursor: pointer;
//   }

.view3DDialogContainer{
    background-color: #00000044;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 999;
    color: 'rgb(93,97,101)'
}

.ViewerProgress {
    position: absolute;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 70%;
    z-index: 999999;
    background-color: #f2f4f6;
}

.animProgress{
    width: 100%;
    height: 100%;
    position: relative;
}



.view3DDialog{
    width: 50%;
    height: 70%;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width:600px) {

    .fDialogContainer{
        width: 100%;
    }

    .fDialog{
        width: 80%;
    }

    .view3DDialog{
        width: 80%;
    }
    
} 

.spinnerContainer{
    width: 50%;
    height: 50%;
}

.headerDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.closeIcon{
    align-self: flex-end;
    padding-left: 10px;
    padding-bottom: 0px;
    margin-top: 10px;
    // margin-right: 10px;
    margin-left: auto;
    font-size: 20;
    border: 5px solid white;
    color: gray;
    background-color: white;
}

.dialogTitle{
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
}
.hideDialog{
    display: none;
}

@media screen and (max-width: 600px) {

    .closeIcon{
        font-size: 12;
    }
}