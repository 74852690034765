.pickerParent{
    background-color: rgb(242, 244, 246);
    width: 100%;
    padding-bottom: 10px;
    & span{
        font-weight: bold;
        padding-left: 10px;
    }
    & img{
        height: 20px;
    }
    &_title{
        padding: 10px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    &_underline{
        width: 100%;
        background-color: #00000055;
        height: 1px;
    }
   
}

.pickerInput{
    background-color: green;
    height: 20px;
    max-height: 20px;
    position: absolute;
    left:0;
    opacity: 0;
}