@import '../sass/variables.scss';

.addToCartButton{
    display: flex;
    width:80px;
    max-width: 80px;
    height: 70px;
    padding:10px 0px;
    background-color: transparent;
    border: 1px solid transparent;
    margin: 0px;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    & span{
        font-weight: 500;
        margin-top: 5px;
    }
 }
 @media screen and (max-width: 600px) {
     .addToCartButton{

        max-height:calc(0.2 * #{$controls_cell_ht});
        height: calc(0.2 * #{$controls_cell_ht});
        color: white;
        // margin-top: $vertical_margin;
        margin-left: $horizontal_margin;
        overflow: hidden;
        background: blue;
        padding:0px 0px;
         & span{
             margin-top: 0px;
             font-size: x-small;
         }
         & img{
            height:calc(0.4 * #{$header_height});
            width: 30px;
        }
     }
 }
 .addToCartButton:focus {
    outline: none;
    box-shadow: none;
 }