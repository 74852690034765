.fDialogContainer{
    background-color: #00000044;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 999;
    color: 'rgb(93,97,101)'
}

.fDialog{
    width: 30%;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width:600px) {

    .fDialogContainer{
        width: 100%;
    }

    .fDialog{
        width: 80%;
       
    }
    
} 

footer{
   
    display: flex;
    padding: 10px;
}

.closeIcon{
    align-self: flex-end;
    padding-left: 10px;
    padding-bottom: 0px;
    margin-top: 10px;
    margin-right: 10px;
    font-size: 20;
    border: 5px solid white;
    color: gray;
    background-color: white;
}

.confirmation{
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    display: block;
}
.confirmationInput{
    margin-right: 10px;
}

.actionButtons{

    background-color: gray;
    color: white;
    flex-grow: 1;
    flex-grow: 1;
    margin: 10px;
    font-weight: bold;
    border-radius: 15px;
    padding: 5px;
    border: 1px solid gray;

}

.dialogText{
    padding-left: 20px;
    padding-right: 20px;
}

.dialogTitle{
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
}

@media screen and (max-width: 600px) {
    .dialogText{
        font-size: 12px;
    }

    .closeIcon{
        font-size: 12;
    }

    .confirmation{
        font-size: 13px;
        vertical-align: middle;
    }
    .confirmationInput{
        font-size: 12px;
        vertical-align: middle;
    }
}