.container{
   width: 100%;
   height: 100%;
   position: absolute;
   left: 0;
   top: 0;
   z-index: 101;
   background-color: #fff;
   display: flex;
   flex-direction: column;
}

.title{
   padding: 10px;
   text-align: center;
   text-transform: uppercase;
   border-bottom: 1px solid #DCDEE5;

   font-family: var(--main-font);
   font-weight: bold;
   font-size: 14px;

   letter-spacing: 0.12em;
   text-transform: uppercase;

   /* black */

   color: #404040;
}


// ===================

.body{
   width: 100%;
   display: flex;
   flex-wrap: wrap;
   flex: 1;
   overflow: hidden;
   overflow-y: auto;
}
.font{
   width: 100%;
   border: none;
   background-color: #fff;
   padding: 10px 8%;
   text-align: left;

   font-weight: normal;
   font-size: 16px;
   color: #404040;
   cursor: pointer;
   transition: all .3s ease;
   outline: none;
   &:hover{
      background-color: #4a68ff44;
   }
   &_selected{
      position: relative;
      &:before{
         content:'\2714';
   
         position: absolute;
         right: 8%;
         top: 50%;
         width: 23px;
         height: 23px;
   
         color: #4A69FF;
         z-index: 101;
         font-size: 16px;
         display: flex;
         align-items: center;
         justify-content: center;
         transform: translate(0, -50%);
      }
   }
}


// ===================

.buttons{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 10px 15px 0 15px;
   border-top: 1px solid #DCDEE5;
}
.btn{
   width: 42%;
   padding: 10px;

   font-family: var(--main-font);
   font-size: 10px;
   line-height: 12px;
   font-weight: bold;

   letter-spacing: 0.12em;
   text-transform: uppercase;
   color: #818699;
   background: #fff;
   border: 1px solid #818699;
   transition: all .3s ease;
   outline: none;

   cursor: pointer;
   &_save{

      border: 1px solid #fff;
      color: #fff;
      background-color:  #4A69FF;
   }
   &:hover{

      border: 1px solid #fff;
      color: #fff;
      background-color:  #4A69FF;
   }
   &:disabled{
      cursor: not-allowed;
      color: #818699;
      background: #fff;
      border: 1px solid #818699;
   }
}