.container{
   width: 100%;
   height: 100%;
   position: absolute;
   background-color: #fff;
   left: 0;
   top:0;
   z-index: 100;
}

.title{
   padding: 10px;
   text-align: center;
   text-transform: uppercase;
   border-bottom: 1px solid #DCDEE5;

   font-family: var(--main-font);
   font-weight: bold;
   font-size: 14px;

   letter-spacing: 0.12em;
   text-transform: uppercase;

   /* black */

   color: #404040;
}
// ===================
.body{
   padding: 0 3%;
   &_text{
      width: 100%;
      padding: 6px 0 12px;
      border-bottom: 1px solid #DCDEE6;
   }
   &_cont{
      width: 100%;
      padding: 12px 0;
      border: none;
      outline: none;
      border-bottom: 1px solid #DCDEE6;
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: #404040;
      background: transparent;
      font-family: var(--main-font);
      font-weight: 500;
      font-size: 16px;
   }
}

button.body_cont{
   cursor: pointer;
   & *{
      pointer-events: none;
   }
}

.cont{
   position: relative;
}

.text{
   width: 100%;
   border: 1px solid #DCDEE5;
   padding: 13px 12px;
   height: 72px;
   resize: none;

   color: #404040;
   font-family: var(--main-font);
   font-weight: 500;
   outline: none;
   &:focus{
      border: 1px solid #4A69FF;
   }

}

.contInfo{
   display: flex;
   align-items: center;
   & img{
      margin-left: 5px;
   }
}
.font{
   font-family: var(--main-font);
   font-size: 16px;
   font-weight: normal;
   color: #404040;
}
.color{
   min-width: 22px;
   min-height: 22px;
   box-shadow: 0 0 3px 1px rgba(0,0,0,0.2);
}

.alignment{
   width: 40px;
   height: 32px;

   background-color: #fff;
   border: 1px solid #DCDEE6;
   color: #404040;
   text-transform: uppercase;
   font-weight: 500;
   font-size: 12px;
   font-family: var(--main-font);
   cursor: pointer;
   outline: none;
   &_active{
      position: relative;
      box-shadow: 0 0 2px 1px #4A69FF;
   }
}


.contTitle{   
   font-family: var(--main-font);
   font-size: 15px;
   font-weight: 500;
   color: #404040;
}

// ===================

.buttons{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 10px 15px 0 15px;
   border-top: 1px solid #DCDEE5;
}
.btn{
   width: 42%;
   padding: 10px;

   font-family: var(--main-font);
   font-size: 10px;
   line-height: 12px;
   font-weight: bold;

   letter-spacing: 0.12em;
   text-transform: uppercase;
   color: #818699;
   background: #fff;
   border: 1px solid #818699;
   transition: all .3s ease;
   outline: none;

   cursor: pointer;
   &_save{

      border: 1px solid #fff;
      color: #fff;
      background-color:  #4A69FF;
   }
   &:hover{

      border: 1px solid #fff;
      color: #fff;
      background-color:  #4A69FF;
   }
   &:disabled{
      cursor: not-allowed;
      color: #818699;
      background: #fff;
      border: 1px solid #818699;
   }
}

.form_cont{
   width: 100%;
   display: flex;
   flex-wrap: wrap;
   padding: 8px 0;
}
.form{
   width: 50%;
   margin-bottom: 5px;
   & .contTitle{
      margin-bottom: 5px;
   }
}