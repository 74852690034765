@import '../sass/variables.scss';

.container{
   width: 100%;
   padding: 20px 3%;
   display: grid;
   grid: auto / 35% 65%;
   //justify-content: space-between;
   align-items: center;
   font-size: 16px;
   height: 100px;
   columns: 2;
   // height: 12%;
   // background-color: #F2F4F6;
   // position: absolute;
   // left: 0;
   // top: 0;
   // z-index: 99;
      // @media screen and (max-width: 1440px){
   //    max-width: 150px;
   // }
}

@media screen and (max-width: 600px) {

   .container{
      //width: 100%;
      grid : auto auto / auto;
      columns: 1;
      max-height: $header_height;
      padding: 5px 2%;
      overflow-y: visible;
      padding: 0px 0%;
   
   }
   
}

.logoContainer{
   grid-column: 1/ span 1;

  // max-width: 212px;
  
   //max-height: 50px;
   
   // position: relative;
   // z-index: 99;
   & img{
      max-height: 50px;
      width: auto;
      max-width: 100%;
   }

   // @media screen and (max-width: 1440px){
   //    max-width: 150px;
   // }
}

.clearallBtn{
   display: none;
}

@media screen and (max-width: 600px) {

   .logoContainer{
      grid-row: 1/ span 1;
      display: flex;
      flex-direction: row;
      & img{
         max-height:calc(0.4 * #{$header_height});
         margin-left: $horizontal_margin;
         padding-top: $vertical_margin;
      }
      
   }

   .clearallBtn{
     display: flex; 
     justify-content: flex-end;
     flex-grow: 1;
   }

   
   .content{
      margin-left: $horizontal_margin;
      margin-right: $horizontal_margin;
      // margin-bottom: $vertical_margin;
      max-height:calc(0.6 * #{$header_height});
      background-color:  #40404022;

   }
   .dataCol{
      padding: 0px;
   }
   
}

// .prodInfo{
//    padding: 10px 15px;
//    font-size: 1.2em;
//    font-family: var(--default-font);
//    text-transform: uppercase;
   
// }
// =============
.data{
   grid-column: 2 / span 1;
   width: 100%;
   display: flex;
   align-items: center;
}

.dataCol{
   flex: 1 0;
   padding: 10px;
   &_sm{
      flex: 0.6;
   }
}

@media screen and (max-width: 600px) {

   .data{
      grid-column : 1/ span 1;
      grid-row: 2/ span 1;
   }
   .dataCol{
      &_sm{
         display: none;
      }
   }
   
}


.content{
   background-color: #fff;
   padding: 1px 5px 1px 5px;
   height: 48px;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   

   font-family: var(--main-font);
 

   font-weight: 600;
   font-size: 16px;


   color: #404040;
}

.content_title{
   text-transform: uppercase;
   font-size: 12px;
   font-weight: bold;
   letter-spacing: 0.12em;
}
