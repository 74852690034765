.sliderDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.slider {
    margin-left: 10px;
    margin-right: 5px;
    width: 100%;
}

.rc-slider-track {
    position: absolute;
    height: 4px;
    background-color: #000000;
    border-radius: 6px;
}
.rc-slider-handle {
    border: solid 2px #000000;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: #000000;
    box-shadow: 0 0 0 5px #000000;
}
