// =========================================input

.input{
   width: 120px;
   display: flex;
   justify-content: space-between;
   &_btn{
      width: 32px;
      min-width: 32px;
      height: 32px;
      text-align: center;
      font-size: 20px;
      font-weight: 300;
      border: 1px solid #DCDEE6;
      background-color: #fff;
      color: #404040;
   }
   &_inpt{
      width: 56px;
      min-width: 56px;
      height: 32px;
      text-align: center;
      color: #404040;
      border: 1px solid #DCDEE6;

      font-size: 16px;
      font-weight: 500;
      font-family: var(--mon);

   }
}

.btn{
   width: 100%;
   border: none;
   color: #fff;
   background-color: #4A69FF;
   height: 30px;
   text-transform: uppercase;
}