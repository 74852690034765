.container{
   width: 35%;
   height: 80%;
   position: absolute;
   background-color: #fff;
   left: 0;
   top:0;
   z-index: 100;

   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

@media screen and (max-width: 600px) {
   .container{
      width: 100%;
      height: 100%;
   }
}
.title{
   padding: 10px;
   text-align: center;
   text-transform: uppercase;
   border-bottom: 1px solid #DCDEE5;

   font-family: var(--main-font);
   font-weight: bold;
   font-size: 14px;

   letter-spacing: 0.12em;
   text-transform: uppercase;

   /* black */

   color: #404040;
}

.img_cont{
   flex:1 0;
   overflow: hidden;
   overflow-y: auto;
   display: flex;
   flex-wrap: wrap;
   align-content: flex-start;
   scrollbar-width: thin;
   scrollbar-color: #797979 #fff !important;
}


.img_filters{
   width: 100%;
   padding: 7px;
   display: flex;
   flex-wrap: wrap;
   align-self: flex-start;
   align-content: flex-start;
}
.filter{
   padding: 5px;
   height: 30px;
   background-color: #fff;
   color: #818699;
   background: #fff;
   border: 1px solid #818699;
   margin: 1px;
   white-space: nowrap;
   flex: 1;
   transition: all .3s ease;
   outline: none;
   cursor:pointer;

   &_active{
      border: 1px solid #fff;
      color: #fff;
      background-color:  #4A69FF;
   }
}

.tabBut{
   cursor: pointer;
   padding: 10px;
   flex-grow: 1;
   font-size: 14px;
   position: relative;
   text-align: center;
   background-color: #DCDEE5;
   &:hover{
      background-color: #00000011;
   }
}
.tabBut_selected{
background-color: white;
&:hover{
   background-color: white;
}
}


   
.tabLayout{
   display: flex;
   
}

.img{
   max-height: 110px;
   margin-left: 0.16%;
   margin-right: 0.16%;
   margin-top: 5px;
   max-width: 33%;
   overflow: hidden;
   position: relative;
   transition: transform .2s ease;
   
   
   & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      pointer-events: none;
      background-color: #fff;//rgba(0,0,0,.1);
      border: 1px solid #ddd;
      border-radius: 4px;
      padding: 2px;
   }
   &:hover{
     border : 1px solid rgba(0, 140, 186, 0.5);
      // box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
      // -ms-transform: scale(1.5); /* IE 9 */
      // -webkit-transform: scale(1.5); /* Safari 3-8 */
      // transform: scale(1.5);
      // z-index: 999;
        
   }
}
.img_selected{
   position: relative;
   &:before{
      content:'\2714';

      position: absolute;
      right: 7px;
      top: 7px;
      width: 23px;
      height: 23px;

      background-color: #4A69FF;
      z-index: 101;
      color: #fff;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
   }
   & img{
      border: 1px solid #4A69FF;
      

   }
}

.notf{
   width: 100%;
   padding: 10px;
   text-align: center;

   font-family: var(--main-font);
   font-weight: bold;
   font-size: 14px;

   letter-spacing: 0.12em;
   text-transform: uppercase;

   /* black */

   color: #404040;

}

.buttons{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 10px 15px 0 15px;
   border-top: 1px solid #DCDEE5;
}
.btn{
   width: 42%;
   padding: 10px;

   font-family: var(--main-font);
   font-size: 10px;
   line-height: 12px;
   font-weight: bold;

   letter-spacing: 0.12em;
   text-transform: uppercase;
   color: #818699;
   background: #fff;
   border: 1px solid #818699;
   transition: all .3s ease;
   outline: none;

   cursor: pointer;
   &_save{

      border: 1px solid #fff;
      color: #fff;
      background-color:  #4A69FF;
   }
   &:hover{

      border: 1px solid #fff;
      color: #fff;
      background-color:  #4A69FF;
   }
   &:disabled{
      cursor: not-allowed;
      color: #818699;
      background: #fff;
      border: 1px solid #818699;
   }
}

.unSplashContainer {
   position: relative;
   width: 50%;
 }
 
 .unSplashImage {
   opacity: 1;
   display: block;
   width: 100%;
   height: auto;
   transition: .5s ease;
   backface-visibility: hidden;
 }
 
 .unSplashMiddle {
   transition: .5s ease;
   opacity: 0;
   position: absolute;
   bottom: 10%;
   left: 5%;
   width: 80%;
   //transform: translate(-50%, -50%);
   //-ms-transform: translate(-50%, -50%);
   text-align: left;
 }
 .unSplashMiddle_left{
   text-align: right;
   left: 15%;
 }
 
 .unSplashContainer:hover .unSplashImage {
   opacity: 0.99;
 }
 
 .unSplashContainer:hover .unSplashMiddle {
   opacity: 1;
 }
 
 .unSplashText {
   color: white;
   font-size: 8px;
   font-weight: bold;
   // padding: 16px 32px;
 }

 a:link, a:visited {
   color: white;
   text-align: center;
   text-decoration: none;
   display: inline-block;
   text-decoration: underline;
 }