.btn{
   border: none;
   width: 100%;
   height: 100%;
   background: var(--main-btn-bgcolor);
   color: var(--main-btn-color);
   font-family: var(--main-font);
   font-size: 12px;
   line-height: 15px;
   letter-spacing: 0.12em;
   text-transform: uppercase;
   cursor: pointer;
   outline: none;
   opacity: 0.85;
   transition: all .3s ease;
   
   &:hover{
      opacity: 1;
   }

   &:disabled{
      background: skyblue;
      opacity: 0.85;
      cursor: default;
   }
}