.cover {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center; 
}

.content{
   text-transform: uppercase;
   font-family: var(--main-font);
   font-weight: var(--main-fw);
}
