$viewport_ht: 95vh;
$viewport_wd: 100%;
$header_height: 15vh;
$vertical_margin: 1vh;
$horizontal_margin: 2%;
$work-panel-color: transparent;
$container_ht: calc(#{$viewport_ht} - #{$header_height});
$fabric_cell_ht: calc(0.5 * #{$container_ht});
$controls_cell_ht: calc(0.5 * #{$container_ht} - #{$vertical_margin});
$ruler_thickness: 40px;
$scrollbar_thickness: 10px;
$fabric_width: calc(#{$viewport_wd} - #{$ruler_thickness} - #{$scrollbar_thickness} - 2*#{$horizontal_margin});
$fabric_height: calc(#{$fabric_cell_ht} - #{$ruler_thickness});
$scollbar_left: calc(#{$viewport_wd} - #{$scrollbar_thickness} - #{$horizontal_margin});
$fabric_tile_panel_ht: calc(0.8 * #{$controls_cell_ht});
$fabric_length_panel_ht: calc(0.2 * #{$controls_cell_ht});
$fabric_image_config_ht: calc(0.8*#{$fabric_tile_panel_ht})

