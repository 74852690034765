.container{
   position: fixed;
   left: 0;
   top: 0;
   z-index: 9999999;
   pointer-events: none;
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   // background-color: rgba(255,255,255,1);
   background: rgb(242, 244, 246);
   transition: all .2s ease;
   &_item{
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      animation: rotate 1s linear infinite;
      & img{
         width: 100%;
      }
   }
}

@keyframes rotate{
   0%{transform: rotate(0);}
   100%{transform: rotate(180deg);}
}