@import '../sass/variables.scss';

.imageSideBarParent{
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    border-radius: 10px;
    // box-shadow: 3px 3px silver;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #797979 #fff !important;
}
.imageSideBarParent:hover{
    overflow-y: auto;
   
}
/* Let's get this party started */
::-webkit-scrollbar {
    width: 8px;
}
 
/* Track */
::-webkit-scrollbar-track {
    
    -webkit-border-radius: 4px;
    border-radius: 4px;
    border-color: transparent;
    background: transparent;

}
 
/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 4px;
    border-radius: 4px;
   
    border-color: transparent;
    background: #797979;// rgba(0,0,0,0.6);
    -webkit-box-shadow: inset 0 0 1px  rgba(0,0,0,0.6); 
    box-shadow: inset 0 0 1px rgba(0,0,0,0.6);
  
}

.imageActions{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
}



.actionIcon{
    width: 20px;
    height: 20px;
    object-fit: contain;
}
.actionIconButton{
    display: block;
    width:30px;
    max-width: 30px;
    height: 30px;
    padding:5px;
    background-color: transparent;
    // border: 1px solid transparent;
    border: none;
    margin: 0px;
    cursor: pointer;
}
.actionIconButton:focus {
    outline: none;
    box-shadow: none;
}

.imageRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.tile{
    border-radius: 10px;
}


.imageSideBar.inputNumber {
    // -moz-appearance: textfield;
    width: 100%;
    height: 32px;
 
    border: 1px solid grey;
    outline: none;
 
    font-family: var(--main-font);
    font-weight: bold;
    font-size: 10px;
    line-height: 32px;
 
    letter-spacing: 0.12em;
    text-transform: uppercase;
    
 
    cursor: pointer;
 }

 .imageSideBar.dimensions{
     flex-grow: 1;
     text-align: left;
     color: gray;
     font-size: 10px;
     margin: 0 5px;
 }

 .imageSideBar.lengthChangeBtn {
    display: none;
 }
       
 .imageSideBar.imageControls{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
}
       
 @media screen and (max-width: 600px) {
    .imageSideBarParent{
        display: flex;
        flex-direction: column;
        border: 0px solid gray;
        border-radius: 0px;
        // box-shadow: 3px 3px silver;
        width: 100%;
        overflow: hidden;
        height: $fabric_image_config_ht;
        max-height: $fabric_image_config_ht;
    }

    .individualTile{

        // height: calc(0.8 * 0.5*#{$fabric_image_config_ht});
        // max-height: calc(0.8 * 0.5*#{$fabric_image_config_ht}) ;
        height:auto;
        max-height: calc(0.8 * 0.5*#{$fabric_image_config_ht}) ;
        width: calc(0.9*0.8*0.5*#{$fabric_image_config_ht});
        max-width: calc(0.9*0.8*0.5*#{$fabric_image_config_ht});
        object-fit: contain;
        border-radius: 2px; 
        background-color: 'transparent';
        padding-left: 10px;
    }

    .parentClassName{
        width: 20%;
        max-width: 20%;
       
    }

    .imageSideBar.inputNumber {
        height: 25px;
        font-size: 9px;
        align-self: center;
        vertical-align: center;
        width: 100%;
        min-width: 20px;
        margin: 0px;
     }

    .imageSideBarParent:hover{
        overflow-y: overlay;
       
    }
    /* Let's get this party started */
    ::-webkit-scrollbar {
        width: 8px;
    }
     
    /* Track */
    ::-webkit-scrollbar-track {
        
        -webkit-border-radius: 4px;
        border-radius: 4px;
        border-color: transparent;
        background: transparent;
    
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 4px;
        border-radius: 4px;
       
        border-color: transparent;
        background: #797979;//rgba(0,0,0,0.6);
        -webkit-box-shadow: inset 0 0 1px  rgba(0,0,0,0.6); 
        box-shadow: inset 0 0 1px rgba(0,0,0,0.6);
      
    }
    
    .actionIcon{
        width: 14px;
        height: 14px;
        object-fit: contain;
    }
    .actionIconButton{
        display: block;
        width:30px;
        max-width: 30px;
        height: 30px;
        padding:5px;
        background-color: transparent;
        // border: 1px solid transparent;
        border: none;
        margin: 0px;
        cursor: pointer;
    }
    .actionIconButton:focus {
        outline: none;
        box-shadow: none;
    }
    
    .imageRow{
        display: flex;
        flex-direction: row;
        align-items: center;
        height: calc(0.4*#{$fabric_image_config_ht});
        max-height: calc(0.4*#{$fabric_image_config_ht});
        overflow: hidden;
    }
    .imageSideBar.imageControls{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        overflow: hidden;
        flex-grow: 1;
        flex-wrap: wrap;
    }
    .imageSideBar.imageActions{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    
    .tile{
        border-radius: 10px;
    }
    
    .dimensions{
        flex-grow: 1;
        text-align: left;
        color: gray;
        font-size: 10px;
        margin: 0 5px;
    }

    /* Chrome, Safari, Edge, Opera */
   input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
        width: 65%;
        align-items: center;
        text-align: center;
    }

    .imageSideBar.lengthChangeBtn {
        display: flex;
        background-color: black;
        color: white;
        width: 20px;
        height: 25px;
        font-size: 9px;
        outline: none;
        padding-left: 8px;
        align-items: center;
        text-align: center;
        justify-content: center;
        border: none;
      }
 }