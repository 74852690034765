

.app-container{
    display: flex;
    flex-direction: row;
    .control-panel{
        width: 35%;
        min-width: 300px;
        display: flex;
        flex-direction: column;
    }
    .editor{
        width:65%;
        min-width: 500px;
        display: flex;
    }
}

.default-style{

}