@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');


@import url("https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap");
@import url("https://fonts.googleapis.com/css?family=Arbutus&display=swap");
@import url("https://fonts.googleapis.com/css?family=Baloo&display=swap");
@import url("https://fonts.googleapis.com/css?family=Gloria+Hallelujah&display=swap");
@import url("https://fonts.googleapis.com/css?family=Kaushan+Script&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css?family=Marcellus+SC&display=swap");
@import url("https://fonts.googleapis.com/css?family=Monoton&display=swap");
@import url("https://fonts.googleapis.com/css?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css?family=Righteous&display=swap");
@import url("https://fonts.googleapis.com/css?family=Shrikhand&display=swap");
@import url("https://fonts.googleapis.com/css?family=Trocchi&display=swap");
@import url('https://fonts.googleapis.com/css?family=Cinzel&display=swap');
@import url('https://fonts.googleapis.com/css?family=Fjalla+One&display=swap');

html {
  /* overflow-x: hidden; */
  /* overflow-y: hidden; */
  /* scrollbar-color: #008de4 #0d3b97;*/
  overflow-y: hidden;
  scrollbar-width: thin;
  scrollbar-color: #797979 #fff !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type=number]::-webkit-inner-spin-button {
  height: 32px;
}

input[type=number]::-moz-number-spin-box {
  height: 32px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  box-sizing: border-box;
}


:root{
  --main-font: 'Montserrat', sans-serif;
  --main-fw: 400;
  --main-btn-color: rgba(255,255,255,1);
  --main-btn-bgcolor: rgba(74,105,255,1)
}


input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
    cursor: pointer; 
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff; 
  border: 1px solid #DCDEE5;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #DCDEE5; 
  border: 1px solid #DCDEE5;
  border-top: none;
  border-bottom: none;
  box-shadow: inset 0 0 1px 1px #fff;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #797979; 
}

.max-cont{
  width: 100%;
  max-width: 1440px;
  min-width: 950px;
  max-height: 800px;
  margin: 0 auto;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width:600px) {
  .max-cont{
    min-width: 100px;
    min-height: 0px; 
  }
}


