@import "../sass/variables.scss";

.container{
   width: 100%;
   height: 100%;
   position: fixed;
   z-index: 9999;
   left: 0;
   top: 0;
   min-width: 950px;
   background-color: rgba(0,0,0,0.4);
   pointer-events: none;
}

.svgIcn path{
   fill:rgba(131,36,253, 1);
 }
 
 .svgIcn rect {
   stroke:rgba(131,36,253, 1);
 }

.data{
   width: 100%;
   position: absolute;
   padding: 0 3%;
   display: grid;
   grid: auto / 35% 65%;
   columns: 2;
}

.preview{
  
   grid-column: 1/span 1;
   padding-top: 40px;
   display: flex;
   flex-direction: row-reverse;
   justify-content: flex-end;
   align-items: flex-end;
   transform: translate(50%, 25% );
   &_title{
      max-width: 231px;
      width: 50%;
      text-transform: uppercase;
      color: rgba(131,36,253,1);
      text-align: center;
      margin-left: 20px;
      font-family: var(--main-font);
      font-size: 14px;
      font-weight: 800;
      line-height: 17px;     
      letter-spacing: 0.12em;
      //padding-top: 20px;
     // transform: translate(20%,100%);
      
   }
   &_arrow{
      // -webkit-transform: scaleX(1.5);
      transform: rotate(-90deg) scaleY(-1);
     // padding-bottom: 50px;
     margin-bottom: 50px;
      //transform: scale(-5, 1.5) rotate(-70deg) skewX(-50deg) skewY(-10deg);
    }
}

.selectorPlaceholder{
   grid-column: 2/span 1;
   padding: 20px 0%;
   display: flex;
   align-items: center;
   height: 100px;
   width: 100%;
}


@media screen and (max-width: 600px) {

   .container{
      min-width: 0;
   }

   .data{
      width: 100%;
      display: grid;
      grid : auto auto / 100%;
      columns: 1;
      padding: 5px 2%;
      padding: 0px 0%;
      width: 100%;
      height: 100vh;
      justify-content: flex-start;
   }
   .selectorPlaceholder{
      min-width: 100%;
      grid-column : 1/ span 1;
      grid-row: 1/span 1;
      margin-right: $horizontal_margin;
      margin-bottom: $vertical_margin;
      padding-top: calc(0.4 * #{$header_height}); 
      max-height:#{$header_height};
   }

   .preview{
      grid-column : 1/ span 1;
      grid-row: 2/span 1;
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      transform: translate(0%,-60%);
      //transform: translate(10%,-50% );
      justify-content: flex-end;
      align-items: flex-end;
     
      &_title{
         //transform: translate(50%,10%);
         
      }
      &_arrow{
      //   -webkit-transform: scaleX(-1);
      //   transform: scaleX(-1);
      //transform: rotate(-90deg) scaleY(-1);
     
      }
   }
}
