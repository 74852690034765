@import '../../sass/variables.scss';
.container{
   width: 40px;
   height: 40px;
   background-color: #fff;
   display: flex;
   flex-direction: column;
   align-items: center;
   color: #818699;
   border: none;
   outline: none;
   cursor: pointer;
   padding: 0;

   *{
      pointer-events: none;
   }
   &_icon{
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #DCDEE5;
      margin-bottom: 0px;
      font-weight: normal;
   }
   &_title{
      font-family: var(--main-font);
      font-weight: inherit;
      font-size: 9px;
      line-height: 10px;
      /* identical to box height */
      text-align: center;
      color: inherit;
   }
}

.pattern_icon{
   width: 40px;
   height: 40px;
}

// @media screen and (max-width: 600px){
//    .pattern_icon{
//       height: calc(0.8*0.8*0.5*#{$fabric_image_config_ht});
//       width: auto;
//       max-height: calc(0.8*0.8*0.5*#{$fabric_image_config_ht});
     
//    }
//    .container{
//       height: calc(0.8*0.5*#{$fabric_image_config_ht});
//       max-height: calc(0.8*0.5*#{$fabric_image_config_ht});
//       width: calc(0.8*0.8*0.5*#{$fabric_image_config_ht});
//       &_icon{
//          height: calc(0.8*0.8*0.5*#{$fabric_image_config_ht});
//          max-height: calc(0.8*0.8*0.5*#{$fabric_image_config_ht});
//          width: auto;
//       }
//       &_title{
//          height: calc(0.2*0.8*0.5*#{$fabric_image_config_ht});
//          max-height: calc(0.2*0.8*0.5*#{$fabric_image_config_ht});
//       }
//    }
// }

.active{
   .container_icon{
      border: 1px solid #4A69FF;
      background-color: #4A69FF99;
   }
   color: #4A69FF;
   font-weight: bold;
}