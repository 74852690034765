.upload-button{
    position: relative;
    margin-top: 1rem;
    padding: 0 0.5rem;
    font-size: 13px;

    &-display{

        display: flex;
        align-items: center;

        img{
            margin-right: 4px;
        }

        background-color: white;
    }
}

.upload-input-concealer{
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.upload-icon {
    &::before {
        content: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDQ1NSA0NTUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ1NSA0NTU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwb2x5Z29uIHBvaW50cz0iNDU1LDIxMi41IDI0Mi41LDIxMi41IDI0Mi41LDAgMjEyLjUsMCAyMTIuNSwyMTIuNSAwLDIxMi41IDAsMjQyLjUgMjEyLjUsMjQyLjUgMjEyLjUsNDU1IDI0Mi41LDQ1NSAyNDIuNSwyNDIuNSANCgk0NTUsMjQyLjUgIi8+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8L3N2Zz4NCg==');
    width: 10px;
    height: 10px;
        background-size: 10px;
        display: inline-block;
        margin-right: 0.5rem;
    }
}
