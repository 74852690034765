.container{
   position: absolute;
   max-width: 280px;
   width: 80%;
   top:calc(100% - 15px);
   right: 0;
   background-color: #fff;
   box-shadow: 0 0 2px rgba(0,0,0,.2);
   z-index: 101;
}
.title{
   padding: 12px 12px 4px 12px;

   font-weight: 500;
   font-size: 16px;
   font-family: var(--main-font);
   color: #404040;

}

.body{
   padding: 0 8px 8px 8px;
   display: flex;
   flex-wrap: wrap;
}
.btn_cont{
   padding: 4px;
   min-width: 30px;
   display: flex;
   align-items: center;
   justify-content: center;
}
.btn{
   border: none;
   outline: none;
   min-width: 22px;
   min-height: 22px;
   box-shadow: 0 0 3px rgba(0,0,0,.1);
   transition: all .3s ease;
   font-family: var(--main-font);
   font-size: 15px;
   cursor: pointer;
   &:hover{
      box-shadow: 0 0 4px 2px #4a68ff44;
   }
}